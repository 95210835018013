import React, { Component } from 'react';
import queryString from 'query-string';
import KovaUtils from '../../KovaUtils';
import lotMapUtils from '../../utils/lotMapUtils';
import styles from './App.module.css';
import NewCards from '../NewCards/NewCards';
import Compare from '../Compare/Compare';
import PhotoGallery from '../PhotoGallery/PhotoGallery';
import LoadingPage from '../LoadingPage/LoadingPage';
import { fetchJSONFile, getCustomFields, createModelObject } from './utils';
import io from "socket.io-client";
import GlobalConfig from "../../utils/GlobalConfig";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allCommunities: null,
      allModels: null,
      cardData: null,
      compareActive: null,
      defaultSalesCenter: 1,
      exclusionList: null,
      galleryActive: false,
      loading: true,
      lotData: null,
      lotStatusConfig: null,
      matterportConfig: null,
      modelIndex: null,
      quickMoveIn: null,
      roomId: null,
      salesCenterConfig: null,
      salesCenterObject: null,
      socket: null
    };

    this.communitiesArray = [
      { name: 'All Home Collections', communityRID: 'all'},
    ];
  }

  componentDidMount() {
    this.pullSalesCenterConfig();
    this.retrieveS3Configs();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      salesCenterConfig,
      salesCenterObject,
      lotData,
      allModels,
      cardData
    } = this.state;

    // on initial load, after the S3Configs are pulled, set the SalesCenterObject
    if (salesCenterConfig && !prevState.salesCenterConfig) {
      this.initializeSalesCenter();
    }

    if (salesCenterObject && !prevState.salesCenterObject) {
      this.fetchCommunities();
    }

    if (allModels && !prevState.allModels) {
      this.getLots();
    }

    if (lotData && allModels && (!prevState.lotData || !prevState.allModels)) {
      this.combineArrays();
    }
  }

  setModelIndex = (modelIndex) => {
    this.setState({ modelIndex });
  }

  /**
   *will grab lotStatus and matterport.json
   */
  retrieveS3Configs = async () => {
    try {
      const [
        lotStatusConfig,
        matterportConfig
      ] = await Promise.all([
        fetchJSONFile('lotStatus.json'),
        fetchJSONFile('matterport.json'),
      ]);
      this.setState({
        lotStatusConfig,
        matterportConfig
      });
    } catch (err) {
      console.error(err);
    }
  }


  /**
   * sets the sales center information.
   *
   * pulls from S3 bucket OR mocksalescenter of goodall data
   */
  pullSalesCenterConfig = async () => {
    const salesCenterConfig = await fetchJSONFile('salesCenter.json');

    this.setState({ salesCenterConfig });
  }

  initializeSalesCenter = async () => {
    const { salesCenterConfig, defaultSalesCenter } = this.state;

    // find Query Params
    let salesCenterId = Number(
      queryString.parse(window.location.search).SaleCenterId
    );
    let roomId = queryString.parse(window.location.search).RoomId;

    const socket = io(GlobalConfig.get(GlobalConfig.Key.SOCKET_URL));
    socket.emit('join room', { name: 'join room', roomId: roomId });

    // set Default Query Params
    if (!salesCenterId) {
      salesCenterId = defaultSalesCenter;
    }
    if(!roomId) {
      roomId = 'noRoom';
    }
    const salesCenterObject = salesCenterConfig.find((community) => community.salesCenterId === salesCenterId);
    const allCommunities = [...salesCenterObject.collections];
    allCommunities.push(this.communitiesArray[this.communitiesArray.length - 1]);
    this.setState({ allCommunities, salesCenterObject, roomId, socket });
  }

  fetchCommunities = async () => {
    const { allCommunities, salesCenterObject } = this.state;

    let modelData = null;
    const models = [];
    let model = null;
    await Promise.all(allCommunities.map(async (community, i) => {
      if (i < (allCommunities.length - 1)) {
        model = await KovaUtils.fetchCommunityModels(community.communityRID);
        model.forEach((plan) => {
          const modelPlan = plan;
          modelPlan.RID = community.communityRID;
          plan.CardType = 'Model';

          if (!plan.MarketingName) {
            plan.MarketingName = plan.Name;
          }
          const removeText = (description) => {
            const oldDesc = description.split(' ');
            let newDesc = '';
            oldDesc.forEach((descriptor, index) => {
              if (index === 0) {
                newDesc = descriptor;
              }
              if (index !== (oldDesc.length - 1) && index > 0) {
                newDesc = `${newDesc} ${descriptor}`;
              }
            });
            return newDesc;
          };
          // check for text in desc field
          if (plan.DescrBaths.includes('Baths')) {
            plan.DescrBaths = removeText(plan.DescrBaths);
          }
          if (plan.DescrBedrooms.includes('Bed')) {
            plan.DescrBedrooms = removeText(plan.DescrBedrooms);
          }
          if (plan.DescrSqf.includes('Sq')) {
            plan.DescrSqf = removeText(plan.DescrSqf);
          }
          // check for format other than number
          if (plan.DescrBaths.includes('/')) {
            plan.DescrBaths = plan.DescrBaths.replace(/ 1\/2/g, '.5');
          }
          if (plan.DescrBedrooms.includes('/')) {
            plan.DescrBedrooms = plan.DescrBedrooms.replace(/ 1\/2/g, '.5');
          }
          plan.newField = 'new field';
          plan.id = plan.ModelRID;
          // model.id needs to be used. something duplicate field.
          plan.name = plan.Name.split('(')[0];
          plan.nhPhoto = plan.PresentationImageUrl;
          plan.sqf = plan.Sqf;
          plan.bath = plan.NumBedrooms;
          plan.bed = plan.NumBaths;
          plan.price = plan.BasePrice;
          if (plan.MarketingName) {
            plan.marketingName = plan.MarketingName;
          } else {
            plan.marketingName = plan.Name;
          }
          models.push(modelPlan);
        });
      }
    }));

    modelData = models;

    let exclusionKeys = [];
    const keys = Object.keys(modelData);
    let listToExclude = [];
    if (salesCenterObject.excludedModels) {
      exclusionKeys = salesCenterObject.excludedModels;
    }

    const excludeTheseModels = [];

    modelData.forEach((newModel) => {
      exclusionKeys.forEach((exclusionKey) => {
        if (newModel.Name.includes(exclusionKey)) {
          excludeTheseModels.push(newModel);
        }
      });
    });

    const newModelArray = [];
    modelData.forEach((newModel) => {
      if (!excludeTheseModels.includes(newModel)) {
        newModelArray.push(newModel);
      }
    });

    newModelArray.forEach((model, i) => {
      model.index = i;
    })

    this.setState({ allModels: newModelArray, exclusionList: exclusionKeys });
  }

  toggleCompareActive = (modelIndex) => {
    this.setState((prevState) => ({
      compareActive: !prevState.compareActive,
      modelIndex
    }));
  }

  toggleGalleryActive = (modelIndex) => {
    //console.log(`togglingGallery: modelIndex=${modelIndex}`)
    if (modelIndex >= 0) {
      const actualIndex = modelIndex;
      this.setState((prevState) => ({
        galleryActive: !prevState.galleryActive,
        modelIndex: actualIndex
      }));
    } else {
      this.setState((prevState) => ({
        galleryActive: !prevState.galleryActive
      }));
    }
  }

  getLots = async () => {
    const { salesCenterObject } = this.state;

    const newObj = {};

    const lotPromises = salesCenterObject.collections.map((c) => c.communityRID).map((communityRID) => (
      KovaUtils.fetchCommunityLots(communityRID)
    ));

    const newLotData = {};

    await Promise.all(lotPromises)
      .then((response) => {
        response.forEach((res, i) => {
          newLotData[salesCenterObject.collections[i].communityRID] = response[i];
        });
      });

    const lotData = await this.processAllLots(newLotData);

    this.setState({ lotData });
  }

  /**
   * Using lotStatus Config, filter all LotData for quickmoveins and give them fields.
   *
   * runs after lots are grabbed
   */
  processAllLots = async (newLotData) => {
    const { lotStatusConfig, salesCenterObject, allModels } = this.state;
    const lotArray = [];
    const noSlsRID = [];
    salesCenterObject.collections.map((c) => c.communityRID).forEach((communityRID) => {
      newLotData[communityRID].forEach((lot, index) => {
        if (lot.Description !== "DO NOT USE") {

          const lotStatus = lotMapUtils.getLotStatus(lot, lotStatusConfig);

          const obj = {};
          obj.RID = communityRID;
          obj.lotRID = lot.LotRID;
          obj.selector = `#_${lot.LotRID}`;
          obj.textSelector = `#L_${lot.LotRID}`;
          obj.lotNum = Number(lot.Description.split('Lot ')[1]);
          obj.type = lot.Type;
          obj.info = lot.Status;
          if (lot.Model) {
            Object.assign(obj, lot.Model);
          }
          obj.index = index;
          obj.highlightColor = lotStatus[1].color;
          obj.blockNum = Number(lot.Description.split(', ')[1].split('Block ')[1]);
          obj.name = `Block ${obj.blockNum} - Homesite ${obj.lotNum}`;
          obj.photos = [];
          obj.AvailableModels = lot.AvailableModels;
          obj.QuickSQF = null;
          obj.QuickBeds = null;
          obj.QuickBaths = null;
          obj.QuickGarages = null;
          obj.QuickPrice = null;
          [obj.status] = lotStatus;
          if (lot.Model) {
            obj.model = lot.Model;
          }

          if (lotStatus[0] === 'QUICKMOVEIN') {
            if (lot.SlsOrd) {
              obj.SlsOrdRID = lot.SlsOrd.SlsOrdRID;
              obj.CardType = 'Quick';
              lotArray.push(obj);
            } else {
              const theModel = allModels.find((model) => {
                return (Number(lot.AvailableModels[0].Value) === model.ModelRID)});
              const tempObj = Object.create(theModel);
              obj.model = tempObj;
              obj.QuickSQF = 'N/A';
              obj.QuickBeds = 'N/A';
              obj.QuickBaths = 'N/A';
              obj.QuickPrice = 'N/A';
              noSlsRID.push(obj);
            }
          }
        }
      });
    });
    let newArray = [];
    if (lotArray.length > 0) {
      newArray = await getCustomFields(lotArray);
    } else {
      newArray = lotArray;
    }

    noSlsRID.forEach((lot) => {
      newArray.push(lot);
    });

    const secondNewModelArray = [];

    newArray.forEach((model) => {
      const newModel = model;
      newModel.MarketingName = model.model.MarketingName;
      secondNewModelArray.push(newModel);
    });

    const lotModelArray = newArray.map((lot) => {
      const modelCopy = allModels.find((model) => model.MarketingName === lot.MarketingName);
      if (!modelCopy) {
        throw new Error("Could not find the model for this lot! Is it web-enabled?")
      }
      const newLotObj = {};
      Object.assign(newLotObj, lot);
      newLotObj.PresentationImageUrl = modelCopy.PresentationImageUrl;
      return newLotObj;
    });

    return lotModelArray;
  };

  combineArrays = async () => {
    const {
      lotData,
      allModels,
      matterportConfig,
      allCommunities
    } = this.state;
    const cardData = [...lotData, ...allModels];
    const formattedModelArray = await createModelObject(cardData, matterportConfig, allCommunities)
    this.setState({ cardData: formattedModelArray });
  }

  render() {
    const {
      allCommunities,
      cardData,
      salesCenterObject,

      allModels,
      compareActive,
      communityRID,
      compareModels,
      modelIndex,
      galleryActive,
      modelData,
        roomId,
        socket
    } = this.state;

    //console.log(`galleryActive=${galleryActive}, ModelIndex=${modelIndex}, AllModels=${allModels && allModels[modelIndex]}, allModels.length=${allModels && JSON.stringify(allModels)}`)
    return (
      <div className={styles.App}>
        {!cardData ? (
          <div
            styles={styles.loadingScreen}
            style={
              {
                height: '100%',
                width: '100%',
                backgroundColor: 'white',
                zIndex: 10,
                position: 'absolute',
              }
            }
          >
            <LoadingPage message={'loading your floorplans'} />
          </div>
        ) : (
        <div className={styles.container3}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {/* will need to do these. SMH. DANG */}
          {compareActive ? (
            <Compare
              communityRID={communityRID}
              modelData={allModels}
              modelIndex={modelIndex}
              setModelIndex={this.setModelIndex}
              toggleCompareActive={this.toggleCompareActive}
            />
          ) : galleryActive ? (
                  allModels[modelIndex] ? (
                      <PhotoGallery
                          model={allModels[modelIndex]}
                          toggleGalleryActive={this.toggleGalleryActive}
                      />
                  ) : <>Failed to load model information. Please reload!</>
          ) : (
            <div className={styles.modelContainer}>
              {allCommunities && (
                <NewCards
                  cardData={cardData}
                  communitiesArray={allCommunities}
                  toggleCompareActive={this.toggleCompareActive}
                  toggleGalleryActive={this.toggleGalleryActive}
                  salesCenterObject={salesCenterObject}
                  socket={socket}
                  roomId={roomId}
                />
              )}
            </div>
          )}
        </div>
        )}
      </div>
    );
  }
}

export default App;
