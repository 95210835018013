import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AWS from 'aws-sdk';
import {Gallery} from "devextreme-react/gallery";
import styles from './PhotoGallery.module.css';
import './styles.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import backButtonImg from '../../img/Button_Exit.png';
import GlobalConfig from "../../utils/GlobalConfig";

class PhotoGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: 'all',
      galleryData: null
    };
  }

  componentDidMount() {
    this.generateGalleryData();
  }

  // TODO: remove superfluous call to S3
  generateGalleryData = async () => {
    try {
      const photoData = await this.getPhotoData();
      // Remove parent dir
      photoData.shift();
      const galleryData = this.formatByDir(photoData);
      this.setState({ galleryData });
    } catch (err) {
      // TODO: Replace console.error with Alert/Error component
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  /**
   * getPhotoData fetches all objects (directories and img files) from an S3
   * bucket that exist within a given prefix.
   * @return {Array<Object>} The array of directory and img objects
   */
  getPhotoData = () => new Promise((resolve, reject) => {
    const { model } = this.props;
    const s3 = this.connectS3();
    s3.listObjects({ Prefix: `${model.RID}/${model.ModelRID}/img/` }, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data.Contents);
      }
    });
  })

  connectS3 = () => {
    AWS.config.region = GlobalConfig.get(GlobalConfig.Key.S3_REGION);
    AWS.config.credentials = new AWS.Credentials(
      GlobalConfig.get(GlobalConfig.Key.S3_ID),
      GlobalConfig.get(GlobalConfig.Key.S3_KEY)
    );

    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: GlobalConfig.get(GlobalConfig.Key.S3_BUCKET) }
    });
    return s3;
  }
  //https://kova.oakwoodhomesco.com/KovaProductionWebConfigurator/api/v4/Communities/GetModels/undefined?=44f8b1255f1148c4d15b1f3b332c4090

  /**
   * formatByDir formats photoData into a galleryData object where each
   * directory within photoData is a property that refrences an array of
   * img paths specific to that directory.
   * @param {Array<Object>} photoData - the array of directory and photo objects
   * @return {Object} The formatted data
   */
  formatByDir = (photoData) => {
    const dirs = photoData.filter((obj) => obj.Key.slice(-1) === '/');
    const photos = photoData.filter((obj) => obj.Key.slice(-1) !== '/');
    const galleryData = {};
    galleryData.all = [];
    dirs.forEach((dir) => {
      const dirPath = dir.Key.split('/');
      const dirName = this.getDirName(dirPath);
      
      const dirPhotos = photos.filter((photo) => photo.Key.includes(dir.Key))
        .map((photo) => `${GlobalConfig.get(GlobalConfig.Key.S3_URL)}${photo.Key}`);
      galleryData[dirName] = dirPhotos;
      if (!this.isSubDir(dirPath)) {
        galleryData.all = [...galleryData.all, ...dirPhotos];
      }
    });
    return galleryData;
  }

  getDirName = (dirPath) => {
    const dirName = dirPath[dirPath.length - 2];
    return dirName;
  }

  isSubDir = (dirPath) => {
    const isSubDir = dirPath.length > 5;
    return isSubDir;
  }

  listFilterOptions = () => {
    const { galleryData } = this.state;

    return Object.keys(galleryData).map((dirName) =>
      <option key={dirName} value={dirName}>{dirName}</option>);
  }

  handleChange = (event) => {
    this.setState({ filter: event.target.value });
  }

  close = () => {
    const { toggleGalleryActive } = this.props;

    toggleGalleryActive();
  }

  render() {
    const { filter, galleryData } = this.state;

    return galleryData ? (
      <div className={styles.PhotoGallery}>
        <div className={styles.galleryAndHeaderContainer}>
          <div className={styles.galleryHeader}>
            <div className={styles.selectContainer}>
              <select value={filter} onChange={(e) => this.handleChange(e)}>
                {this.listFilterOptions()}
              </select>
              <div className={styles.arrowDown} />
            </div>
          </div>
          <Gallery
            id="gallery"
            dataSource={galleryData[filter]}
            height="90%"
            slideShowDelay={2000}
            loop
            showNavButtons
            showIndicator
            width="100%"
            wrapAround
          />
        </div>
        <button className={styles.exitButton} onClick={() => this.close()} type="button">
          <img src={backButtonImg} alt="back" />
        </button>
      </div>
    ) : (
      null
    );
  }
}

PhotoGallery.propTypes = {
  model: PropTypes.object.isRequired
};

export default PhotoGallery;
