import React from 'react';
import PropTypes from 'prop-types';
import styles from './ButtonPanel.module.css';
import arrowLeft from '../../../img/arrow-left-white.svg';
import arrowRight from '../../../img/arrow-right-white.svg';

const ButtonPanel = ({ handleLeftClick, handleRightClick }) => (
  <div className={styles.ButtonPanel}>
    <div className={styles.buttonWrapper}>
      <button type="button" onClick={handleLeftClick}>
        <img src={arrowLeft} alt="arrow-left" />
      </button>
      <button type="button" onClick={handleRightClick}>
        <img src={arrowRight} alt="arrow-right" />
      </button>
    </div>
  </div>
);

ButtonPanel.propTypes = {
  handleLeftClick: PropTypes.func.isRequired,
  handleRightClick: PropTypes.func.isRequired
};

export default ButtonPanel;
