import React, { Component } from 'react';
import { Range } from 'rc-slider';
import PropTypes from 'prop-types';
import styles from './FilterSlider.module.css';
import FilterHandle from '../../../img/Filter_Slider_Handle_1.png';
import FilterBar from '../../../img/BG_Filter.png';
import 'rc-slider/assets/index.css';


class FilterSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    const {
      index,
      isAdult
    } = this.props;
    const handle1Text = document.getElementsByClassName('rc-slider-handle-1')[index].getAttribute('aria-valuenow');
    const handle2Text = document.getElementsByClassName('rc-slider-handle-2')[index].getAttribute('aria-valuenow');

    const divElement1 = document.createElement('Div');
    const divElement2 = document.createElement('Div');

    divElement1.className = `${styles.value}`;
    divElement2.className = `${styles.value}`;

    let color = '';
    if (isAdult) {
      color = 'rgb(109, 2, 61)';
    } else {
      color = '#123643';
    }

    // Styling it
    divElement1.style.textAlign = 'center';
    divElement1.style.height = '50px';
    divElement1.style.width = '50px';
    divElement1.style.color = color;
    divElement1.style.position = 'relative';
    divElement1.style.top = '24px';
    divElement1.style.left = '-.8rem';
    divElement1.style.fontSize= '1.2rem';
    divElement1.style.fontWeight= '600';
    if (isAdult) {
      divElement1.style.fontFamily = 'Optmia';
    }

    divElement2.style.textAlign = 'center';
    divElement2.style.height = '50px';
    divElement2.style.width = '50px';
    divElement2.style.color = color;
    divElement2.style.position = 'relative';
    divElement2.style.top = '24px';
    divElement2.style.left = '-.8rem';
    divElement2.style.fontSize= '1.2rem';
    divElement2.style.fontWeight= '600';
    if (isAdult) {
      divElement2.style.fontFamily = 'Optmia';
    }

    const paragraph1 = document.createElement('P');
    const paragraph2 = document.createElement('P');

    const text1 = document.createTextNode(handle1Text);
    const text2 = document.createTextNode(handle2Text);

    paragraph1.appendChild(text1);
    paragraph2.appendChild(text2);

    divElement1.appendChild(paragraph1);
    divElement2.appendChild(paragraph2);

    document.getElementsByClassName('rc-slider-handle-1')[index].appendChild(divElement1);
    document.getElementsByClassName('rc-slider-handle-2')[index].appendChild(divElement2);
    this.forceUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      type,
      index
    } = this.props;

    let handle1Text = document.getElementsByClassName('rc-slider-handle-1')[index].getAttribute('aria-valuenow');
    if (type === 'Price') {
      handle1Text = Number(handle1Text / 1000);
      handle1Text = handle1Text.toString();
      handle1Text += 'K';
    }
    let handle2Text = document.getElementsByClassName('rc-slider-handle-2')[index].getAttribute('aria-valuenow');
    if (type === 'Price') {
      handle2Text = Number(handle2Text / 1000);
      handle2Text = handle2Text.toString();
      handle2Text += 'K';
    }
    document.getElementsByClassName('rc-slider-handle-1')[index].childNodes[0].textContent = handle1Text;
    document.getElementsByClassName('rc-slider-handle-2')[index].childNodes[0].textContent = handle2Text;
  }

  addTickText = () => {
    const {
      filter
    } = this.props;

    const filterLabels = [filter.range[0], filter.range[(filter.range.length - 1)]];

    // do translations for larger numbers

    return (
      <div className={styles.labelHolder}>
        <div className={styles.leftValue}>
          {filterLabels[0]}
        </div>
        <div className={styles.rightValue}>
          {filterLabels[1]}
        </div>
      </div>
    );
  }

  render() {
    const {
      filter,
      adjustFilter,
      sliderImage,
      isAdult
    } = this.props;
    return (
      <div className={styles.FilterSlider}>
        <div className={styles.titleName}>
          <img src={sliderImage} alt="slider" />
          {/* {filter.name} */}
        </div>
        <div className={styles.slider}>
          <Range
            min={filter.min}
            max={filter.max}
            dots={false}
            step={filter.step}
            value={filter.activeRange}
            onChange={(e) => adjustFilter(filter.name, e)}
            handleStyle={
              [
                {
                  // backgroundImage: `url(${FilterHandle})`,
                  // backgroundPosition: 'center',
                  // backgroundSize: 'cover',
                  // backgroundRepeat: 'no-repeat',
                  height: '25px',
                  width: '25px',
                  border: 'none',
                  top: '7px',
                  boxShadow: 'none',
                  backgroundColor: '#A5B11F',
                },
                {
                  // backgroundImage: `url(${FilterHandle})`,
                  backgroundPosition: 'center',
                  // backgroundSize: 'cover',
                  // backgroundRepeat: 'no-repeat',
                  height: '25px',
                  width: '25px',
                  border: 'none',
                  top: '7px',
                  boxShadow: 'none',
                  backgroundColor: '#A5B11F',
                }
              ]
            }
            trackStyle={[isAdult ? {
              backgroundColor: 'rgb(109, 2, 61)',
              height: '5px',
              top: '11.5px',
            } : {
              backgroundColor: '#1A3464',
              height: '5px',
              top: '11.5px',
            }]}
            railStyle={{
              // backgroundImage: `url(${FilterBar})`,
              // backgroundPosition: 'center',
              // backgroundSize: '101% 100%',
              // backgroundRepeat: 'no-repeat',
              height: '3.5px',
              width: '101%',
              // boxShadow: '0 0 5px rgba(0,0,0,.05)',
              backgroundColor: 'rgb(211, 216, 225)',
              top: '11px',
            }}
            ariaValueTextFormatterGroupForHandles={[(e) => e]}
          />
        </div>
      </div>
    );
  }
}

FilterSlider.propTypes = {
  filter: PropTypes.object.isRequired,
  adjustFilter: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  sliderImage: PropTypes.string.isRequired,
  isAdult: PropTypes.bool.isRequired,
};

export default FilterSlider;
