/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './NewCards.module.css';
import BuilderCard from '../BuilderCards/BuilderCard/BuilderCard';
import FilterSlider from '../BuilderCards/FilterSlider/FilterSlider';
import filterBedNormal from '../BuilderCards/BuilderCard/img/newBed.png';
import filterBathNormal from '../BuilderCards/BuilderCard/img/newBath.png';
import filterSqFtNormal from '../BuilderCards/BuilderCard/img/newSqFt.png';
import filterPriceNormal from '../BuilderCards/BuilderCard/img/dollarSign.png';
import filterBedAdult from '../BuilderCards/BuilderCard/img/purpBed.png';
import filterBathAdult from '../BuilderCards/BuilderCard/img/purpBath.png';
import filterSqFtAdult from '../BuilderCards/BuilderCard/img/purpSqFt.png';
import filterPriceAdult from '../BuilderCards/BuilderCard/img/purpDollarSign.png';
import newArrowLeft from '../BuilderCards/BuilderCard/img/arrow_back_ios-1@2x.png';
import newArrowRight from '../BuilderCards/BuilderCard/img/arrow_back_ios@2x.png';
import adultArrowLeft from '../BuilderCards/BuilderCard/img/purpArrayBack.png';
import adultArrowRight from '../BuilderCards/BuilderCard/img/purpArrowNext.png';
import Exit from '../../img/Button_Exit.png';
import { hasMultipleCommunities } from '../BuilderCards/utils';
import newCollections from '../BuilderCards/BuilderCard/img/newCollections.png';
import CommunityDropDown from '../CommunityDropDown/CommunityDropDown';
import GlobalConfig from "../../utils/GlobalConfig";


class NewCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMatterportLink: null,
      initialLoad: true,

      modelsChanged: false,

      filterState: null,

      modelsPresented: null,

      filterTypeApplied: ['MODEL'],
      selectedCommunities: [],
      numCards: 0,
    };

    this.filterTitles = [{ Price: 'basePrice' }, { Bathrooms: 'numBaths' }, { Bedrooms: 'numBedrooms' }, { 'Square Footage': 'sqf' }];
  }

  componentDidMount() {
    this.setModels();
    this.setFilters();
    this.checkNum();
    this.setSelectedCommnunities('all');
  }

  componentDidUpdate(prevProps, prevState) {
    const { modelsChanged, filterState, filterTypeApplied, selectedCommunities} = this.state;

    if (filterState !== prevState.filterState) {
      this.checkNum();
    }
    if (filterTypeApplied !== prevState.filterTypeApplied) {
      this.checkNum();
    }
    if (selectedCommunities !== prevState.selectedCommunities) {
      this.checkNum();
    }
  }

  setSelectedCommnunities = (RID) => {
    const { communitiesArray } = this.props;
    const { selectedCommunities } = this.state;

    let RIDArray = [];

    communitiesArray.forEach((community) => {
      if (RID === 'all') {
        RIDArray.push(community.communityRID);
      }

      // if we want an inclusive array, uncomment this line and comment out
      // the if clause below

      // if (RID !== 'all' && !selectedCommunities.includes(community.RID)) {
      //   RIDArray.push(community.RID);
      // }
    });

    if (RID !== 'all') {
      RIDArray = [RID];
    }

    this.setState({ selectedCommunities: RIDArray });
  }

  setModels = () => {
    const { cardData } = this.props;
    const { initialLoad } = this.state;

    if (initialLoad) {
      this.setState({ modelsPresented: cardData, initialLoad: false });
    } else {
      this.setState({ modelsChanged: false });
    }
  }

  // ------------------------ create filters ---------------------- //
  // ------------------------ change Filter ------------------------ //
  adjustFilter = (filterType, event) => {
    const {
      filterState,
    } = this.state;
    const newFilters = [...filterState];
    const selectedFilter = filterState.find(
      (object) => object.name === filterType
    );
    selectedFilter.activeRange = event;
    this.setState({ filterState: newFilters });
  }

  applySpecFilter = (arrayOfModels) => {
    const {
      filterState
    } = this.state;

    const modelsToPresent = [];
    if (filterState && filterState[0] && filterState[0].activeRange.length > 0) {
      arrayOfModels.forEach((model) => {
        let pass = true;
        this.filterTitles.forEach((filterName) => {
          const filterDesc = Object.keys(filterName)[0];
          const modelDataField = Object.values(filterName)[0];
          const rangeOfFilter = filterState.find((element) =>
            element.name === filterDesc).activeRange;

          const modelValue = model[`${modelDataField}Array`];

          if (pass === true) {
            if (modelValue[0] <= rangeOfFilter[rangeOfFilter.length - 1]
              && modelValue[1] >= rangeOfFilter[0]) {
              pass = true;
            } else if (modelValue[1] === 0) {
              pass = true;
            } else {
              pass = false;
            }
          }
        });
        if (pass) {
          modelsToPresent.push(model);
        }
      });

      modelsToPresent.sort((a, b) => {
        const textA = a.marketingName;
        const textB = b.marketingName;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      return modelsToPresent;
    }
    return arrayOfModels;
  }

  applyTypeFilter = (arrayOfModels) => {
    const { filterTypeApplied } = this.state;
    const modelsToPresent = [];

    arrayOfModels.forEach((model) => {
      let pass = false;

      filterTypeApplied.forEach((filter) => {
        if (model.saleType === filter) {
          pass = true;
        }
      });

      if (pass) {
        modelsToPresent.push(model);
      }
    });

    modelsToPresent.sort((a, b) => {
      const textA = a.marketingName;
      const textB = b.marketingName;
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    return modelsToPresent;
  }

  applyCommunityFilter = (arrayOfModels) => {
    const { selectedCommunities } = this.state;
    const modelsToPresent = [];

    arrayOfModels.forEach((model) => {
      const pass = selectedCommunities.includes(model.communityRID);
      if (pass) {
        modelsToPresent.push(model);
      }
    });

    modelsToPresent.sort((a, b) => {
      const textA = a.marketingName;
      const textB = b.marketingName;
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    return modelsToPresent;
  }
  // ------------------------ change Filter ------------------------ //


  setFilters = () => {
    const filterContainer = [];
    this.filterTitles.forEach((filter) => {
      const newFilter = {};
      newFilter.name = Object.keys(filter)[0];
      newFilter.range = this.findRange(filter[newFilter.name]);
      const [min, max, step] = this.findMaxMin(
        Object.values(filter)[0], this.findRange(filter[newFilter.name])
      );
      newFilter.activeRange = [min, max];
      newFilter.step = step;
      newFilter.min = min;
      newFilter.max = max;
      newFilter.marks = this.setMarks(newFilter.step, newFilter.range);
      filterContainer.push(newFilter);
    });
    this.setState({ filterState: filterContainer });
  }

  findMaxMin = (filterName, listOfVals) => {

    let minimumVal = null;
    let maximumVal = null;
    let diff = null;
    // eslint-disable-next-line no-param-reassign
    listOfVals = listOfVals.filter((value) => value > 0);
    if (filterName === 'basePrice') {
      minimumVal = listOfVals[0];
      maximumVal = listOfVals[listOfVals.length - 1];
      let correctionVal = Math.floor(minimumVal % 25000);
      minimumVal -= correctionVal;
      correctionVal = -1 * (Math.floor(maximumVal % 25000) - 25000);

      maximumVal += correctionVal;
      diff = 25000;
    } else if (filterName === 'numBaths') {
      minimumVal = listOfVals[0];
      maximumVal = listOfVals[listOfVals.length - 1];
      minimumVal = Math.floor(minimumVal);
      maximumVal = Math.ceil(maximumVal);
      diff = 0.5;
    } else if (filterName === 'numBedrooms') {
      minimumVal = listOfVals[0];
      maximumVal = listOfVals[listOfVals.length - 1];
      diff = 1;
    } else {
      minimumVal = listOfVals[0];
      maximumVal = listOfVals[listOfVals.length - 1];
      let correctionVal = Math.floor(minimumVal % 100);
      minimumVal -= correctionVal;
      correctionVal = -1 * (Math.floor(maximumVal % 100) - 100);

      maximumVal += correctionVal;
      diff = 100;
    }
    const stepNum = (minimumVal - maximumVal) / diff;
    return [minimumVal, maximumVal, diff, stepNum];
  }

  findRange = (filterName) => {
    const {
      cardData,
    } = this.props;
    let listOfVals = [];

    if (cardData?.length > 0) {
      cardData.forEach((model) => {
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(model[`${filterName}Array`][0])) {
          listOfVals.push(model[`${filterName}Array`][0]);
          listOfVals.push(model[`${filterName}Array`][1]);
        }
      });

      listOfVals = listOfVals.sort((a, b) => a - b);

      if (filterName === 'basePrice') {
        let minimumVal = listOfVals[0];
        let maximumVal = listOfVals[listOfVals.length - 1];
        let correctionVal = Math.floor(minimumVal % 25000);
        minimumVal -= correctionVal;
        correctionVal = -1 * (Math.floor(maximumVal % 25000) - 25000);

        maximumVal += correctionVal;
        // listOfVals.push(maximumVal);
      }
      // console.log([listOfVals[0], listOfVals[listOfVals.length - 1]]);
      // return [listOfVals[0], listOfVals[listOfVals.length - 1]];
      return listOfVals;
    }
    return [0, 0];
  }

  findSteps = (range) => {
    let diff = range[range.length - 1] - range[0];
    diff /= 4;
    return diff;
  }

  setMarks = (step, range) => {
    const output = {};
    let j = 0;

    for (let i = range[0]; i <= range[range.length - 1]; i += step) {
      output[j] = String(i);
      j += 1;
    }
    return output;
  }

  createFilters = () => {
    const {
      filterState
    } = this.state;
    const {
      salesCenterObject
    } = this.props;

    return filterState.map((filter, i) => {
      let imageSrc = '';
      if (salesCenterObject.isActiveAdult) {
        if (filter.name === 'Price') {
          imageSrc = filterPriceAdult;
        }
        if (filter.name === 'Bathrooms') {
          imageSrc = filterBathAdult;
        }
        if (filter.name === 'Square Footage') {
          imageSrc = filterSqFtAdult;
        }
        if (filter.name === 'Bedrooms') {
          imageSrc = filterBedAdult;
        }
      } else {
        if (filter.name === 'Price') {
          imageSrc = filterPriceNormal;
        }
        if (filter.name === 'Bathrooms') {
          imageSrc = filterBathNormal;
        }
        if (filter.name === 'Square Footage') {
          imageSrc = filterSqFtNormal;
        }
        if (filter.name === 'Bedrooms') {
          imageSrc = filterBedNormal;
        }
      }
      return (
        <FilterSlider
          key={filter.name}
          index={i}
          filter={filter}
          adjustFilter={this.adjustFilter}
          type={filter.name}
          sliderImage={imageSrc}
          isAdult={salesCenterObject.isActiveAdult}
        />
      );
    });
  }

  // ------------------------ create filters ---------------------- //

  // ----------------------- filter functions --------------------- //
  changeFilterType = (type) => {
    const { filterTypeApplied } = this.state;

    const newFilterArray = [];

    let isTypeIncluded = false;

    filterTypeApplied.forEach((filter) => {
      if (filter !== type) {
        newFilterArray.push(filter);
      } else {
        isTypeIncluded = true;
      }
    });

    if (!isTypeIncluded) {
      newFilterArray.push(type);
    }

    this.setState({ filterTypeApplied: newFilterArray });
  }

  // --------------------- end filter functions ------------------- //
  presentIFrame = (link) => {
    if (link) {
      this.setState({ activeMatterportLink: link });
      return null;
    }
    this.setState({ activeMatterportLink: null });
    return null;
  }

  generateCards = () => {
    const {
      communitiesArray,
      cardData,
      toggleCompareActive,
      toggleGalleryActive,
      salesCenterObject,
      socket,
      roomId
    } = this.props;

    let filteredModels = this.applySpecFilter(cardData);
    filteredModels = this.applyTypeFilter(filteredModels);
    filteredModels = this.applyCommunityFilter(filteredModels);

    if (filteredModels.length === 0) {
      return (
        <div className={styles.noCards}>
          <div className={styles.popUp}>
            <div>
              No homes were found that match.
            </div>
            <div>
              Revise your selections in the filter window or start
              fresh by clearing your selections with the button below
            </div>
            <button type="button" className={styles.resetButton} onClick={this.resetFilters}>
              Clear Filter
            </button>
          </div>
        </div>
      );
    }

    return filteredModels.map((model, index) => {
      //console.log(`filteredmodels: index=${index}, model=${model && JSON.stringify(model)}`)
      return (
        <div
          className={styles.cardContainer}
        >
          <BuilderCard
            floorplanData={filteredModels[index]}
            activateiFrame={this.presentIFrame}
            toggleCompareActive={toggleCompareActive}
            toggleGalleryActive={toggleGalleryActive}
            salesCenterObject={salesCenterObject}
            isActiveAdult={salesCenterObject.isActiveAdult}
            socket={socket}
            roomId={roomId}
          />
        </div>
      );
    });
  }

  checkNum = () => {
    const {
      cardData,
    } = this.props;

    let filteredModels = this.applySpecFilter(cardData);
    filteredModels = this.applyTypeFilter(filteredModels);
    filteredModels = this.applyCommunityFilter(filteredModels);

    this.setState({ numCards: filteredModels.length });
  }

  resetViewLocation = () => {

  }

  arrowClick = (direction) => {
    const containerElement = document.getElementById('floorplanContainerWeb');
    const width = containerElement.clientWidth;
    if (direction === 'right') {
      containerElement.scrollLeft = containerElement.scrollLeft + width;
    } else {
      containerElement.scrollLeft = containerElement.scrollLeft - width;
    }
  };

  resetFilters = () => {
    this.setState({ filterTypeApplied: ['MODEL'] });
    this.setFilters();
  }

  render() {
    const {
      filterState,
      selectedCommunities,
      activeMatterportLink,
      numCards,
      filterTypeApplied
    } = this.state;

    let verticalLayout = GlobalConfig.get(GlobalConfig.Key.LAYOUT) === 'VERTICAL' ? true : false;
    let arrowHeight = verticalLayout ? '0px' : '3rem';
    const { communitiesArray, salesCenterObject } = this.props;

    return (
      <div className={styles.newCards}>
        {activeMatterportLink && (
          <div className={styles.iframeBackground}>
            <div className={styles.iframeContainer}>
              <iframe className={styles.title} title="matterport" src={activeMatterportLink} />
              <button
                className={styles.matterportExit}
                type="button"
                onClick={() => { this.presentIFrame(null); }}
              >
                <img src={Exit} alt="back" />
              </button>
            </div>
          </div>
        )}
          <div styles={styles.BuilderCardsContainer}>
          <div
              label="arrow left"
              type="button"
              className={styles.leftArrow}
              style={salesCenterObject.isActiveAdult ?
                {
                  backgroundImage: `url(${adultArrowLeft})`,
                  height: `${arrowHeight}`
                } : {
                  backgroundImage: `url(${newArrowLeft})`,
                  height: `${arrowHeight}`
                }
              }
              onClick={() => {
                this.arrowClick('left');
              }}
              role="navigation"
            />
            <div
              label="arrow right"
              type="button"
              className={styles.rightArrow}
              style={salesCenterObject.isActiveAdult ?
                {
                  backgroundImage: `url(${adultArrowRight})`,
                  height: `${verticalLayout ? '0px' : '3rem'}`
                } : {
                  backgroundImage: `url(${newArrowRight})`,
                  height: `${arrowHeight}`
                }
              }
              onClick={() => {
                this.arrowClick('right');
              }}
              role="navigation"
            />
            <div className={styles.FloorplanMainPanel}>
              <div className={styles.sortBar}>
                <div className={styles.sortOptions}>
                  {hasMultipleCommunities(communitiesArray) && (
                    <div
                      className={styles.communityDD}
                      style={salesCenterObject.isActiveAdult ? {
                        backgroundImage: `url(${newCollections})`,
                        color: '#621244',
                        fontFamily: 'Optmia',
                      } : {
                        backgroundImage: `url(${newCollections})`,
                        color: '#1A3464',
                      }}
                    >
                      <CommunityDropDown
                        defaultValue={communitiesArray.find((community) => community.communityRID === 'all').name}
                        communities={communitiesArray}
                        resetView={this.resetViewLocation}
                        setSelectedCommnunities={this.setSelectedCommnunities}
                        isActiveAdult={salesCenterObject.isActiveAdult}
                      />
                    </div>
                  )}
                  <div
                    className={styles.filterBox}
                    style={salesCenterObject.isActiveAdult ? {
                      color: '#621244',
                      fontFamily: 'Optmia'
                    } : {
                      color: '#1A3464',
                    }}
                  >
                    <p> Available Homes </p>
                    <label className={styles.checkboxLabel}>
                      <input
                        type="checkbox"
                        checked={filterTypeApplied.includes('QUICKMOVEIN')}
                        onChange={() => this.changeFilterType('QUICKMOVEIN')}
                      />
                      <span className={styles.checkbox} />
                    </label>
                  </div>
                  <div
                    className={styles.filterBox}
                    style={salesCenterObject.isActiveAdult ? {
                      color: '#621244',
                      fontFamily: 'Optmia'
                    } : {
                      color: '#1A3464',
                    }}
                  >
                    <p> Floor Plans </p>
                    <label className={styles.checkboxLabel}>
                      <input
                        type="checkbox"
                        checked={filterTypeApplied.includes('MODEL')}
                        onChange={() => this.changeFilterType('MODEL')}
                      />
                      <span className={styles.checkbox} />
                    </label>
                  </div>
                </div>
                <div className={styles.resultsNum}>
                  {`${numCards} Results`}
                </div>
              </div>
              <div
                className={styles.floorplanContainerWeb}
                id="floorplanContainerWeb"
                ref={this.viewerRef}
                style={verticalLayout ? {
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  position: 'absolute',
                  justifyContent: 'center',
                  top: '50%',
                  left: '50%',
                  height: '67%',
                  transform: 'translate(-50%, -63%)',
                  scrollBehavior: 'smooth',
                  overflowY: 'auto',
                  width: '80%',
                  overflowX: 'scroll',
                } : {
                  display: 'flex',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -63%)',
                  scrollBehavior: 'smooth',
                  width: '80%',
                  overflowX: 'scroll',
                }
                }
              >
                {this.generateCards()}
              </div>

              <div className={styles.SliderHolder}>
                <div className={styles.sliderMargin}>
                  {filterState && this.createFilters()}
                </div>
              </div>
            </div>
          </div>

      </div>
    );
  }
}

NewCards.propTypes = {
  cardData: PropTypes.array.isRequired,
  communitiesArray: PropTypes.array.isRequired,
  toggleCompareActive: PropTypes.func.isRequired,
  toggleGalleryActive: PropTypes.func.isRequired,
  salesCenterObject: PropTypes.object.isRequired,
};

export default NewCards;
