import React from 'react';
import PropTypes from 'prop-types';
import styles from './InfoPanel.module.css';
import bedIcon from '../../../img/bed-icon-black.svg';
import showerIcon from '../../../img/shower-icon-black.svg';

const InfoPanel = ({ model }) => (
  <div className={styles.InfoPanel}>
    {model.MarketingName ? <h1>{model.MarketingName}</h1> : <h1>{model.CommunityModelName}</h1>}
    <div className={styles.infoText}>{model.BasePrice}</div>
    <div className={styles.infoText}>{`${model.DescrSqf} sq ft`}</div>
    <div className={styles.infoGraphicWrapper}>
      <div className={styles.infoGraphic}>
        <img src={bedIcon} alt="bed" />
        <span>{model.DescrBedrooms}</span>
      </div>
      <div className={styles.infoGraphic}>
        <img src={showerIcon} alt="bath" />
        <span>{model.DescrBaths}</span>
      </div>
    </div>
  </div>
);

InfoPanel.propTypes = {
  model: PropTypes.object.isRequired
};

export default InfoPanel;
