import React, { Component } from 'react';
import styles from './CommunityDropDown.module.css';
import OptionBackground from '../../img/Expanded_Option_BG_PNG.png';
import NewOptionBackground from '../BuilderCards/BuilderCard/img/newOptionBackground.png';
import CheckMark from '../../img/Check_Mark.svg';

class CommunityDropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSelection: null,
      open: false,
      ulLocation: null,
    };
  }

  componentDidMount() {
    const {
      defaultValue
    } = this.props;

    this.setState({ currentSelection: 'all' });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      open,
    } = this.state;
    if (open && !prevState.open) {
      const ulLocation = document.getElementById('dDContainer').clientHeight + 10;
      this.setState({ ulLocation: ulLocation });
    }
  }

  createDropDownItems = () => {
    const {
      communities
    } = this.props;

    communities.sort((a, b) => a.name.localeCompare(b.name));

    const {
      currentSelection
    } = this.state;
    const divHeight = document.getElementById('dropDown').clientHeight;
    const checkHeight = Math.floor(divHeight / 2).toString();
    const currentSelectionName = communities.find((community) => community.communityRID === currentSelection);
    return communities.map((community, i) => (
      <li
        key={community.communityRID}
        id={`${i}`}
        className={styles.listItems}
        onClick={() => { this.changeSelectedCommunity(community.communityRID, community.name) }}
        style={
          { 
            height: `${divHeight}px`,
            backgroundImage: `url(${NewOptionBackground})`
          }
        }
      >
        <div className={styles.communityText}
          style={this.props.isActiveAdult ? {
            color: '#621244',
            fontFamily: 'Optmia',
          } : {
            color: '#1A3464',
          }}
        >
          {community.name}
        </div>
        {currentSelection === community.communityRID && (
          <div
            className={styles.checkmark}
            style={
              {
                height: `${divHeight}px`,
                backgroundImage: `url(${CheckMark})`,
                maxHeight: `${checkHeight}px`
              }
            }
          />
        )}
      </li>
    ));
  }

  changeSelectedCommunity = (RID) => {
    const {
      open
    } = this.state;
    const {
      resetView,
      setSelectedCommnunities
    } = this.props;
    
    setSelectedCommnunities(RID);
    resetView(RID);
    this.setState({
      currentSelection: RID,
      open: !open
    });
  }

  findCurrentSelection = () => {
    const {
      currentSelection
    } = this.state;

    const {
      communities,
      defaultValue
    } = this.props;


    let currentName = currentSelection

    if (currentSelection !== defaultValue) {
      let communityName = communities.find((community) => (community.communityRID === currentSelection));
      // communityName = communityName.name;
      currentName = communityName.name;
    }
    return currentName;
  }

  render() {
    const { currentSelection, open } = this.state;

    return (
      <div className={styles.CommunityDropDown}>

        {currentSelection && this.findCurrentSelection()}
        <button
          className={styles.dropDown}
          id="dropDown"
          onClick={() => { this.setState({ open: !open }); }}
          type="button"
        >
          {open && (
            <ul
              className={styles.dDContainer}
              id="dDContainer"
              style={
                {
                  bottom: `-${this.state.ulLocation}px`
                }
              }
            >
              {this.createDropDownItems()}
            </ul>
          )}
        </button>
      </div>
    );
  }
}

export default CommunityDropDown;
