import React, { Component } from 'react';
import PropTypes from 'prop-types';
import S3Utils from '../../utils/S3';
import KovaUtils from '../../KovaUtils';
import ButtonPanel from './ButtonPanel/ButtonPanel';
import InfoPanel from './InfoPanel/InfoPanel';
import styles from './Compare.module.css';
import arrowLeft from '../../img/Arrow_Left.png';
import arrowRight from '../../img/Arrow_Right.png';
import backButtonImg from '../../img/Button_Back.png';
import noImgIcon from '../../img/Icon_No_Image.png';
import LoadingPage from '../LoadingPage/LoadingPage';
import GlobalConfig from "../../utils/GlobalConfig";

class Compare extends Component {
  constructor(props) {
    super(props);
    const { modelIndex } = this.props;

    this.state = {
      activeCompareTypes: null,
      compareTypeIndex: 0,
      compareTypes: null,
      model2Index: modelIndex === 0 ? 1 : 0,
      options: null,
      modelImgs: null
    };
  }

  componentDidMount() {
    this.getOptionArray();
  }

  componentDidUpdate(prevProps, prevState) {
    const { modelIndex } = this.props;
    const { model2Index, options, compareTypes } = this.state;

    if (prevProps.modelIndex !== modelIndex
      || prevState.model2Index !== model2Index) {
      this.setActiveCompareTypes();
    }
    if (!prevState.options && options) {
      this.generateCompareTypes();
    }
    if (!prevState.compareTypes && compareTypes) {
      this.generateModelImgs();
      this.setActiveCompareTypes();
    }
  }

  getOptionArray = async () => {
    const { modelData } = this.props;
    let optionArray = [];
    try {
      const promises = modelData.map((model) =>
        KovaUtils.fetchModelOpts(model.RID, model.ModelRID));
      optionArray = await Promise.all([...promises]);
    } catch (err) {
      console.error(err);
    }
    this.setState({ options: optionArray });
  }

  setActiveCompareTypes = () => {
    const { modelData, modelIndex } = this.props;
    const { compareTypes, model2Index } = this.state;

    const model1 = modelData[modelIndex];
    const model2 = modelData[model2Index];
    const modelCompareTypes = compareTypes[model1.ModelRID];
    const model2CompareTypes = compareTypes[model2.ModelRID];
    let activeCompareTypes = [...modelCompareTypes, ...model2CompareTypes];
    activeCompareTypes = activeCompareTypes.reduce((newArray, compareType) => {
      if (newArray.find((item) => item.text === compareType.text)) {
        return newArray;
      }
      return [...newArray, compareType];
    }, []);

    this.setState({ activeCompareTypes });
  }

  generateCompareTypes = async () => {
    const { modelData } = this.props;
    const baseCompareTypes = [
      { id: 'elevation', text: 'elevation' },
      { id: 'floor_1', text: 'first floor' },
    ];
    const compareTypes = {};
    modelData.forEach((model) => {
      compareTypes[model.ModelRID] = [...baseCompareTypes];

      if (this.hasFloor(model, 'Second Floor')) {
        compareTypes[model.ModelRID].push({ id: 'floor_2', text: 'second floor' });
      }

      if (this.hasFloor(model, 'Third Floor')) {
        compareTypes[model.ModelRID].push({ id: 'floor_3', text: 'third floor' });
      }

      if (this.hasFloor(model, 'Basement')) {
        compareTypes[model.ModelRID].push({ id: 'floor_3', text: 'basement' });
      }
    });

    let compareKeys = Object.keys(compareTypes);
    let newArray = [];
    compareKeys.forEach((key) => {
      const compareArray = compareTypes[key];
      compareArray.forEach((floor) => {
        if (newArray.length === 0) {
          newArray.push(floor);
        }
        let exists = false;
        newArray.forEach((existingFloor) => {
          if(existingFloor.text === floor.text) {
            exists = true;
          }
        })
        if(!exists) {
          newArray.push(floor);
        }
      });
    })

    let reorderArray = [];
    let isBasementIncl = false;
    newArray.forEach((floor, idx) => {
      if(floor.text.includes('basement')) {
        isBasementIncl = floor
      } else {
        reorderArray.push(floor);
      }
    });
    if(isBasementIncl) {
      reorderArray.push(isBasementIncl);
    }

    compareKeys.forEach((key) => {
      compareTypes[key] = reorderArray;
    })

    this.setState({ compareTypes });
  }

  fetchS3Imgs = async (model, compareType) => {
    // Should only implement S3 utils once, use / pass down as utility class
    const s3 = S3Utils.connect();
    const dir = compareType.id === 'elevation' ? 'img' : `mkt/${compareType.id}`;
    const key = `${model.RID}/${model.ModelRID}/${dir}`;
    let images = await S3Utils.listObjectsGraphics(key, s3);
    images = images.Contents.filter((img) => img.Key.includes('.png') || img.Key.includes('.jpg'));

    return images;
  }

  generateModelImgs = async () => {
    const { modelData } = this.props;
    const { compareTypes } = this.state;

    const modelImgs = {};
    const compareTypeEntries = Object.entries(compareTypes);

    const imgPromises = compareTypeEntries.map(([modelRID, compareTypeArr]) => {
      const model = modelData.find((m) => m.ModelRID.toString() === modelRID);

      return compareTypeArr.map((compareType) =>
        this.fetchS3Imgs(model, compareType));
    });

    const imgResults = await Promise.all(imgPromises.map((promises) =>
      Promise.all(promises)));

    compareTypeEntries.forEach(([modelRID, compareTypeArr], index1) => {
      modelImgs[modelRID] = {};
      compareTypeArr.forEach((compareType, index2) => {
        const images = imgResults[index1][index2];
        if (images.length > 0) [modelImgs[modelRID][compareType.id]] = images;
      });
    });

    this.setState({ modelImgs });
  }

  hasFloor = (model, floorName) => {
    const { options } = this.state;
    const modelOpts = options.find((modelOptions) =>
      modelOptions.ModelRID === model.ModelRID);
    const selectedFloor = Object.values(modelOpts.Floors).find((floor) =>
      floor.Description === floorName);

    if (floorName === 'Third Floor' && model.Style === '2-Story') {
      return false;
    }

    if (floorName === 'Basement' && modelOpts.SelectedOptions.FOUNDATION === 'fnd_slab||') {
      return false;
    }

    return selectedFloor;
  }

  hasImg = (modelIndex) => {
    const { modelData } = this.props;
    const { activeCompareTypes, compareTypeIndex, modelImgs } = this.state;
    let compareType = activeCompareTypes[compareTypeIndex];
    const model = modelData[modelIndex];

    if (compareTypeIndex === activeCompareTypes.length) {
      compareType = activeCompareTypes[compareTypeIndex - 1];
    }

    if (modelImgs[model.ModelRID][compareType.id]) {
      return true;
    }

    return false;
  }

  setImgSrc = (modelIndex) => {
    const { modelData } = this.props;
    const { activeCompareTypes, compareTypeIndex, modelImgs } = this.state;
    let compareType = activeCompareTypes[compareTypeIndex];

    if (compareTypeIndex === activeCompareTypes.length) {
      compareType = activeCompareTypes[compareTypeIndex - 1];
    }

    const url = `${GlobalConfig.get(GlobalConfig.Key.S3_URL)}${modelImgs[modelData[modelIndex].ModelRID][compareType.id].Key}`;

    return url;
  };

  decrementModelIndex = () => {
    const { modelData, modelIndex, setModelIndex } = this.props;
    const { model2Index } = this.state;
    let nextIndex;

    if (modelIndex === 0) {
      nextIndex = modelData.length - 1;
    } else {
      nextIndex = modelIndex - 1;
    }
    if (nextIndex === model2Index) {
      nextIndex -= 1;
      if (nextIndex === -1) {
        nextIndex = modelData.length - 1;
      }
    }

    setModelIndex(nextIndex);
  }

  incrementModelIndex = () => {
    const { modelData, modelIndex, setModelIndex } = this.props;
    const { model2Index } = this.state;
    let nextIndex;

    if (modelIndex === modelData.length - 1) {
      nextIndex = 0;
    } else {
      nextIndex = modelIndex + 1;
    }
    if (nextIndex === model2Index) {
      nextIndex += 1;
      if (nextIndex === modelData.length) {
        nextIndex = 0;
      }
    }

    setModelIndex(nextIndex);
  }

  decrementModel2Index = () => {
    const { modelData, modelIndex } = this.props;
    const { model2Index } = this.state;
    let nextIndex;

    if (model2Index === 0) {
      nextIndex = modelData.length - 1;
    } else {
      nextIndex = model2Index - 1;
    }
    if (nextIndex === modelIndex) {
      nextIndex -= 1;
      if (nextIndex === -1) {
        nextIndex = modelData.length - 1;
      }
    }

    this.setState({ model2Index: nextIndex });
  }

  incrementModel2Index = () => {
    const { modelData, modelIndex } = this.props;
    const { model2Index } = this.state;
    let nextIndex;

    if (model2Index === modelData.length - 1) {
      nextIndex = 0;
    } else {
      nextIndex = model2Index + 1;
    }
    if (nextIndex === modelIndex) {
      nextIndex += 1;
      if (nextIndex === modelData.length) {
        nextIndex = 0;
      }
    }

    this.setState({ model2Index: nextIndex });
  }

  decrementCompareTypeIndex = () => {
    const { activeCompareTypes, compareTypeIndex } = this.state;
    let nextIndex;


    if (compareTypeIndex === 0) {
      nextIndex = activeCompareTypes.length - 1;
    } else {
      nextIndex = compareTypeIndex - 1;
    }

    this.setState({ compareTypeIndex: nextIndex });
  }

  incrementCompareTypeIndex = () => {
    const { activeCompareTypes, compareTypeIndex } = this.state;
    let nextIndex;

    if (compareTypeIndex === activeCompareTypes.length - 1) {
      nextIndex = 0;
    } else {
      nextIndex = compareTypeIndex + 1;
    }

    this.setState({ compareTypeIndex: nextIndex });
  }

  setCompareText = () => {
    const { activeCompareTypes, compareTypeIndex } = this.state;

    if (compareTypeIndex === activeCompareTypes.length) {
      this.setState({ compareTypeIndex: activeCompareTypes.length - 1 });
      return activeCompareTypes[compareTypeIndex - 1].text;
    }
    return activeCompareTypes[compareTypeIndex].text;
  }

  render() {
    const { modelData, modelIndex, toggleCompareActive } = this.props;
    const { activeCompareTypes, model2Index, modelImgs } = this.state;

    return activeCompareTypes && modelImgs ? (
      <div className={styles.Compare}>
        <div className={styles.modelWrapper}>
          {this.hasImg(modelIndex) ? (
            <img
              className={styles.modelImg}
              src={this.setImgSrc(modelIndex)}
              alt="model"
            />
          ) : (
            <div className={styles.noImg}>
              <img src={noImgIcon} alt="placeholder" />
              <h2>No Image</h2>
              <div>Floor Unavailable on Selected Model</div>
            </div>
          )}
          <div className={styles.divider} />
          {this.hasImg(model2Index) ? (
            <img
              className={styles.modelImg}
              src={this.setImgSrc(model2Index)}
              alt="model"
            />
          ) : (
            <div className={styles.noImg}>
              <img src={noImgIcon} alt="placeholder" />
              <h2>No Image</h2>
              <div>Floor Unavailable on Selected Model</div>
            </div>
          )}
        </div>
        <div className={styles.controls}>
          <div>
            <InfoPanel model={modelData[modelIndex]} />
            <ButtonPanel
              handleLeftClick={this.decrementModelIndex}
              handleRightClick={this.incrementModelIndex}
            />
          </div>
          <div className={styles.graphicToggle}>
            <div>
              <button onClick={this.decrementCompareTypeIndex} type="button">
                <img
                  alt="arrow-left"
                  src={arrowLeft}
                />
              </button>
              {this.setCompareText()}
              <button onClick={this.incrementCompareTypeIndex} type="button">
                <img
                  alt="arrow-left"
                  src={arrowRight}
                />
              </button>
            </div>
            <button onClick={() => toggleCompareActive(null)} type="button">
              <img src={backButtonImg} alt="back" />
            </button>
          </div>
          <div>
            <InfoPanel model={modelData[model2Index]} />
            <ButtonPanel
              handleLeftClick={this.decrementModel2Index}
              handleRightClick={this.incrementModel2Index}
            />
          </div>
        </div>
      </div>
    ) : (
      <LoadingPage
        message={'loading your comparison images'}
      />
    );
  }
}

Compare.propTypes = {
  modelData: PropTypes.arrayOf(PropTypes.object).isRequired,
  modelIndex: PropTypes.number.isRequired,
  toggleCompareActive: PropTypes.func.isRequired,
  setModelIndex: PropTypes.func.isRequired
};

export default Compare;
