class GlobalConfig {
  constructor() {
    this.config = {};
    this.Key = {
      BUILDER:'BUILDER',
      KOVA_URL:'KOVA_URL',
      KOVA_AUTH:'KOVA_AUTH',
      SOCKET_URL:'SOCKET_URL',
      S3_URL:'S3_URL',
      S3_ID:'S3_ID',
      S3_KEY:'S3_KEY',
      S3_REGION:'S3_REGION',
      S3_BUCKET:'S3_BUCKET',
      VSC_API_URL:'VSC_API_URL',
      LAYOUT: 'LAYOUT',
      GRAPHICS: 'GRAPHICS'
    };
  }

  setConfig(config) {
    this.config = config;
  }

  get(key) {
    return this.config[key];
  }
}

const configInstance = new GlobalConfig();

export default configInstance;
