import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import GlobalConfig from "./utils/GlobalConfig";

fetch('./envconfig.json')
    .then(response => response.json())
    .then((data) => {
        GlobalConfig.setConfig(data);

        ReactDOM.render(<App/>, document.getElementById('root'));

        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister();
    });
