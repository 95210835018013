import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './LoadingPage.module.css';

const LoadingPage = ({
  message
}) => (
  <div className={styles.loadingPage}>
    <div />
    <div />
    <div />
    <div />
    <span className={styles.message}>{message}</span>
  </div>
);

LoadingPage.defaultProps = {
  message: ''
};

LoadingPage.propTypes = {
  message: PropTypes.string,
};

export default LoadingPage;

    