import AWS from 'aws-sdk';
import GlobalConfig from "./GlobalConfig";

export default class S3 {
  static connect = () => {
    AWS.config.region = GlobalConfig.get(GlobalConfig.Key.S3_REGION);
    AWS.config.credentials = new AWS.Credentials(
      GlobalConfig.get(GlobalConfig.Key.S3_ID),
      GlobalConfig.get(GlobalConfig.Key.S3_KEY)
    );

    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: GlobalConfig.get(GlobalConfig.Key.S3_BUCKET) }
    });

    return s3;
  }

  static listObjects = (prefix, s3) =>
    new Promise((resolve, reject) => {
      const params = {
        Bucket: `${GlobalConfig.get(GlobalConfig.Key.S3_BUCKET)}`,
        // Key: key,
        Prefix: prefix,
        MaxKeys: 10
      };
      s3.listObjects(params, (err, response) => {
        if (err) {
          reject(err);
        } else {
          resolve(response);
        }
      });
    })

  static listObjectsGraphics = (prefix, s3) =>
    new Promise((resolve, reject) => {
      const params = {
        Bucket: `${GlobalConfig.get(GlobalConfig.Key.GRAPHICS)}`,
        // Key: key,
        Prefix: prefix,
        MaxKeys: 10
      };
      s3.listObjects(params, (err, response) => {
        if (err) {
          reject(err);
        } else {
          resolve(response);
        }
      });
    })
}
