import KovaUtils from '../../KovaUtils';
import MatterportUtils from '../../utils/MatterPort';
import AWS from 'aws-sdk';
import { index } from 'd3-array';
import GlobalConfig from "../../utils/GlobalConfig";

function setConfigEnv(hostname) {
  let domain = hostname;

  if (domain.indexOf('bimairedev') >= 0 || domain.indexOf('localhost') >= 0) {
    return 'dev';
  }
  if (domain.indexOf('bimairetest') >= 0) {
    return 'test';
  }
  if (domain.indexOf('bimaire') >= 0) {
    return 'prod';
  }

  console.error(`hostname not recognized: ${hostname}`);
  return 'prod';
}

async function fetchJSONFile(key) {
  const { hostname } = window.location;
  const configEnv = setConfigEnv(hostname);
  const response = await fetch(`${GlobalConfig.get(GlobalConfig.Key.VSC_API_URL)}AppFiles/config/${configEnv}/${key}`);
  const json = await response.json();

  return json;
}

/**
 * grabs custom field information. Tries first to grab custom fields.
 * if not present then grabs custom string and breaks it down.
 * If none of those then 0 is default
 *
 * Returns new array of lots.
 *
 * @param quickMoveIn: array containing quick move in lots.
 */
async function getCustomFields(quickMoveIn) {
  const customFieldsBool = [];
  const slsRIDs = [];
  const models = [];
  const firstStringPromises = quickMoveIn.map((lot) => {
    slsRIDs.push(lot.SlsOrdRID);
    customFieldsBool.push(false);
    return KovaUtils.fetchCustomInfo(lot.SlsOrdRID);
  });

  await Promise.all(firstStringPromises)
    .then((response) => {
      response.forEach((res, idx) => {
        const ModelinQuestion = quickMoveIn.filter((model) => model.SlsOrdRID === slsRIDs[idx]);
        models.push(ModelinQuestion);
        const resp = response[idx];
        resp.forEach((field) => {
          if (field.FieldID === 'MoneyDSHprice1') {
            ModelinQuestion[0].QuickPrice = field.MoneyValue;
          }
          if (field.FieldID === 'IntDSHsqft1') {
            ModelinQuestion[0].QuickSQF = field.IntValue;
          }
          if (field.FieldID === 'IntDSHbed1') {
            ModelinQuestion[0].QuickBeds = field.IntValue;
          }
          if (field.FieldID === 'DecimalDSHbath1') {
            ModelinQuestion[0].QuickBaths = field.DecimalValue;
          }
        });

        if (ModelinQuestion[0].QuickPrice && ModelinQuestion[0].QuickSQF
        &&  ModelinQuestion[0].QuickBeds && ModelinQuestion[0].QuickBaths) {
          customFieldsBool[idx] = true;
        }
      });
    });
  const secondArray = [];
  models.forEach((model, idx) => {
    if (!customFieldsBool[idx]) {
      secondArray.push(model);
    }
  });

  if (secondArray.length > 0) {
    const stringPromises = secondArray.map((lot) => {
      return KovaUtils.fetchCustomString(lot[0].SlsOrdRID);
    });

    const newModels = [];
    await Promise.all(stringPromises)
      .then((response) => {
        response.forEach((res, idx) => {
          const string = response[idx][0].Props;
          const stringArry = string.split(';');
          stringArry.forEach((field) => {
            const ModelinQuestion = quickMoveIn.filter((model) => model.SlsOrdRID === response[idx][0].SlsOrdRID);
            if (field.includes('MktSQF')) {
              const newString = field.split('=');
              ModelinQuestion[0].QuickSQF = Number(newString[1]);
            } else if (field.includes('MktBeds')) {
              const newString = field.split('=');
              ModelinQuestion[0].QuickBeds = Number(newString[1]);
            } else if (field.includes('MktBaths')) {
              const newString = field.split('=');
              ModelinQuestion[0].QuickBaths = Number(newString[1]);
            } else if (field.includes('MktGarage')) {
              const newString = field.split('=');
              ModelinQuestion[0].QuickGarages = Number(newString[1]);
            } else if (field.includes('MktPrice')) {
              let newString = field.split('=');
              newString = newString[1].replace(',', '');
              newString = Number(newString);
              ModelinQuestion[0].QuickPrice = newString;
              newModels.push(ModelinQuestion[0]);
            }
            if (!ModelinQuestion[0].QuickSQF) {
              ModelinQuestion[0].QuickSQF = 0;
            }
            if (!ModelinQuestion[0].QuickBeds) {
              ModelinQuestion[0].QuickBeds = 0;
            }
            if (!ModelinQuestion[0].QuickBaths) {
              ModelinQuestion[0].QuickBaths = 0;
            }
            if (!ModelinQuestion[0].QuickPrice) {
              ModelinQuestion[0].QuickPrice = 0;
            }
          });
        });
      });
  }
  const QMICustom = quickMoveIn;

  return QMICustom;
}

const getDirName = (dirPath) => {
  const dirName = dirPath[dirPath.length - 2];
  return dirName;
}

const isSubDir = (dirPath) => {
  const isSubDir = dirPath.length > 5;
  return isSubDir;
}

const formatByDir = (photoData) => {
  const dirs = photoData.filter((obj) => obj.Key.slice(-1) === '/');
  dirs.push({Key: "236/2593/img/int/misc/"})
  const photos = photoData.filter((obj) => obj.Key.slice(-1) !== '/');
  const galleryData = {};
  galleryData.all = [];
  dirs.forEach((dir) => {
    const dirPath = dir.Key.split('/');
    const dirName = getDirName(dirPath);
    const dirPhotos = photos.filter((photo) => photo.Key.includes(dir.Key))
      .map((photo) => `${GlobalConfig.get(GlobalConfig.Key.S3_URL)}${photo.Key}`);
    galleryData[dirName] = dirPhotos;
    if (!isSubDir(dirPath)) {
      galleryData.all = [...galleryData.all, ...dirPhotos];
    }
  });
  return galleryData;
};

const connectS3 = () => {
  AWS.config.region = GlobalConfig.get(GlobalConfig.Key.S3_REGION);
  AWS.config.credentials = new AWS.Credentials(
    GlobalConfig.get(GlobalConfig.Key.S3_ID),
    GlobalConfig.get(GlobalConfig.Key.S3_KEY)
  );

  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: { Bucket: GlobalConfig.get(GlobalConfig.Key.S3_BUCKET) }
  });
  return s3;
};

const getPhotoData = (model) => new Promise((resolve, reject) => {
  const s3 = connectS3();
  s3.listObjects({ Prefix: `${model.RID}/${model.ModelRID}/img/` }, (err, data) => {
    if (err) {
      reject(err);
    } else {
      resolve(data.Contents);
    }
  });
});

const generateGalleryData = async (floorplan) => {

  try {
    const photoData = await getPhotoData(floorplan);
    photoData.shift();
    const galleryData = formatByDir(photoData);
    return galleryData;
  } catch (err) {
    console.error(err);
  }
};

async function createModelObject(modelData, matterportConfig, communitiesArray) {
  const modelObjectArray = [];
  let newGalleryArray = [];
  try {
    const promises = modelData.map((model) => {
      return generateGalleryData(model)
    });
    newGalleryArray = await Promise.all([...promises]);
  } catch (err) {
    console.error(err);
  }

  newGalleryArray.forEach((gallery, i) => {
    // eslint-disable-next-line no-param-reassign
    modelData[i].theNewGallery = gallery.all.length;
  });
  modelData.forEach((floorplan) => {
    const matterport = MatterportUtils.getMatterportLink(
      floorplan.RID,
      floorplan.ModelRID,
      matterportConfig
    );
    const commName = communitiesArray.find((item) =>
      floorplan.RID === item.communityRID);
    const floorplanData = {
      name: floorplan.CommunityModelName,
      matterPortTour: matterport,
      totalOptionsAmount: null,
      totalCost: null,
      leadHistEvtRID: null,
      communityModelName: floorplan.CommunityModelName,
      image: floorplan.PresentationImageUrl,
      modelRID: floorplan.ModelRID,
      communityRID: floorplan.RID,
      numBedrooms: floorplan.NumBedrooms,
      numBaths: floorplan.NumBaths,
      sqf: floorplan.Sqf,
      photoGalleryData: floorplan.theNewGallery,
      communityName: commName.name,
      location: floorplan.Location,
      index: floorplan.index,
    };
    if (floorplan.MarketingName) {
      floorplanData.marketingName = floorplan.MarketingName;
    } else {
      floorplanData.marketingName = floorplan.CommunityModelName;
    }
    if (floorplan.CardType !== 'Model') {
      let priceData = floorplan.QuickPrice.toString();

      priceData = `$ ${priceData}`;
      const lengthPriceData = priceData.length;
      const lengthFromComma = lengthPriceData - 3;
      priceData = `${priceData.substring(0, lengthFromComma)},${priceData.substring(lengthFromComma, lengthPriceData)}.00`;
      if (priceData === ',$ 0.00') {
        priceData = 0;
      }
      floorplanData.numBedrooms = floorplan.QuickBeds;
      floorplanData.numBaths = floorplan.QuickBaths;
      floorplanData.sqf = floorplan.QuickSQF;
      floorplanData.basePrice = priceData;
      floorplanData.priceData = floorplan.QuickPrice;
      floorplanData.descrBath = floorplan.QuickBaths.toString();
      floorplanData.QuickBaths = floorplan.QuickBaths;
      floorplanData.descrBedrooms = floorplan.QuickBeds.toString();
      floorplanData.QuickBeds = floorplan.QuickBeds;
      floorplanData.descrSqf = floorplan.QuickSQF.toString();
      floorplanData.QuickSQF = floorplan.QuickSQF;
      floorplanData.location = floorplan.name;
    } else {
      floorplanData.basePrice = floorplan.BasePrice;
      floorplanData.descrBath = floorplan.DescrBaths;
      floorplanData.descrBedrooms = floorplan.DescrBedrooms;
      floorplanData.descrSqf = floorplan.DescrSqf;
    }

    if (!floorplan.SlsOrdRID) {
      floorplanData.slsOrdRID = 0;
    } else {
      floorplanData.slsOrdRID = floorplan.SlsOrdRID;
    }
    if (floorplan.status === "QUICKMOVEIN") {
      floorplanData.saleType = "QUICKMOVEIN";
    } else {
      floorplanData.saleType = 'MODEL';
    }

    if (floorplanData.descrBath && floorplanData.descrBath.includes('-') && floorplanData.QuickBaths !== 0) {
      floorplanData.numBathsArray = [];
      floorplanData.numBathsArray.push(Number(floorplanData.descrBath.split('-')[0]));
      floorplanData.numBathsArray.push(Number(floorplanData.descrBath.split('-')[1]));
    } else if (floorplanData.QuickBaths === 0) {
      floorplanData.descrBath = 'N/A';
      floorplanData.numBathsArray = [0, 0];
    } else {
      floorplanData.numBathsArray = [];
      floorplanData.numBathsArray.push(Number(floorplanData.descrBath));
      floorplanData.numBathsArray.push(Number(floorplanData.descrBath));
    }
    if (floorplanData.descrBedrooms && floorplanData.descrBedrooms.includes('-') && floorplanData.QuickBeds !== 0) {
      floorplanData.numBedroomsArray = [];
      floorplanData.numBedroomsArray.push(Number(floorplanData.descrBedrooms.split('-')[0]));
      floorplanData.numBedroomsArray.push(Number(floorplanData.descrBedrooms.split('-')[1]));
    } else if (floorplanData.QuickBeds === 0) {
      floorplanData.descrBedrooms = 'N/A';
      floorplanData.numBedroomsArray = [0, 0];
    } else {
      floorplanData.numBedroomsArray = [];
      floorplanData.numBedroomsArray.push(Number(
        floorplanData.descrBedrooms
      ));
      floorplanData.numBedroomsArray.push(Number(
        floorplanData.descrBedrooms
      ));
    }
    if (floorplanData.descrSqf && floorplanData.descrSqf.includes('-') && floorplanData.QuickSQF !== 0) {
      floorplanData.sqfArray = [];
      floorplanData.sqfArray.push(Number(floorplanData.descrSqf.split('-')[0]));
      floorplanData.sqfArray.push(Number(floorplanData.descrSqf.split('-')[1]));
    } else if (floorplanData.QuickSQF === 0) {
      floorplanData.descrSqf = 'N/A';
      floorplanData.sqfArray = [0, 0];
    } else {
      floorplanData.sqfArray = [];
      floorplanData.sqfArray.push(Number(floorplanData.descrSqf));
      floorplanData.sqfArray.push(Number(floorplanData.descrSqf));
    }
    if (floorplanData.basePrice && (!floorplanData.priceData || floorplanData.priceData !== 0 )) {
      const price = floorplanData.basePrice.substring(2).replace(',', '');
      floorplanData.basePriceArray = [];
      floorplanData.basePriceArray.push(Number(price));
      floorplanData.basePriceArray.push(Number(price));
    } else {
      floorplanData.basePrice = 'N/A';
      floorplanData.basePriceArray = [0, 0];
    }
    modelObjectArray.push(floorplanData);
  });

  modelObjectArray.sort((a, b) => {
    const textA = a.marketingName;
    const textB = b.marketingName;
    // eslint-disable-next-line no-nested-ternary
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  return modelObjectArray;
}


export { fetchJSONFile, getCustomFields, setConfigEnv, createModelObject };
