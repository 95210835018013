import axios from 'axios';
import GlobalConfig from "./utils/GlobalConfig";

export default class KovaUtils {
  static fetchCommunityLots = async (commRID) => {
    const response = await axios.get(`${GlobalConfig.get(GlobalConfig.Key.KOVA_URL)}api/v4/Communities/GetLots/${commRID}?auth=${GlobalConfig.get(GlobalConfig.Key.KOVA_AUTH)}`);
    return response.data;
  }

  static fetchCommunityModels = async (commRID) => {
    const response = await axios.get(`${GlobalConfig.get(GlobalConfig.Key.KOVA_URL)}api/v4/Communities/GetModels/${commRID}?auth=${GlobalConfig.get(GlobalConfig.Key.KOVA_AUTH)}`);
    return response.data;
  }

  static fetchCustomString = async (SlsRID) => {
    const response = await axios.get(`${GlobalConfig.get(GlobalConfig.Key.VSC_API_URL)}SlsOrds?slsOrdRID=${SlsRID}&kovaEnv=prod`);
    return response.data;
  }

  static fetchCustomInfo = async (SlsRID) => {
    const response = await axios.get(`${GlobalConfig.get(GlobalConfig.Key.VSC_API_URL)}CustomFields?refObjType=SlsOrd&refObjRID=${SlsRID}&kovaEnv=prod`);
    return response.data;
  }

  static fetchModelOpts = async (communityRID, modelRID) => {
    const url = `${GlobalConfig.get(GlobalConfig.Key.KOVA_URL)}api/v4/CommunityModels/All?CommunityRID=${communityRID}&ModelRID=${modelRID}&floorIndex=-1`;
    const response = await axios.get(`${url}`)
      .catch(() => false);

    return response.data.Plan;
  }
}
