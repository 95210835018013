/* eslint-disable react/prop-types */
import React from 'react';
import io from 'socket.io-client';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import styles from './BuilderCard.module.css';
// import HomeConfig from '../../../img/Button_Blank_Large.png';
// import PersonalizeIcon from '../../../img/Icon_Personalize_FP.svg';
import QuickTag from '../../../img/Quick_Tag.svg';
import newBathIcon from './img/newBath.png';
import newBedIcon from './img/newBed.png';
import newSqFtIcon from './img/newSqFt.png';
import adultBedIcon from './img/newBedGrey.png';
import adultBathIcon from './img/newBathGrey.png';
import adultSqFtIcon from './img/newSqFtGrey.png';
import GlobalConfig from "../../../utils/GlobalConfig";

const BuilderCard = ({
  floorplanData,
  activateiFrame,
  toggleCompareActive,
  toggleGalleryActive,
  salesCenterObject,
  isActiveAdult,
    socket,
    roomId
}) => {
  const sendMessage = () => {
    socket.emit('2D IFP', {
      name: '2D IFP',
      roomId: roomId,
      data: floorplanData
    });
  };

  const sendHSM = () => {
    socket.emit('HSM select', {
      name: 'HSM select',
      roomId: roomId,
      data: floorplanData
    });
  };

  const hasPhotos = () => {
    const photos = floorplanData.photoGalleryData;
    const isQMI = floorplanData.saleType === 'QUICKMOVEIN';
    if (photos && photos > 0 && !isQMI) {
      return true;
    }
    return false;
  };

  const checkFloorplanType = () => {
    if (floorplanData.saleType === 'QUICKMOVEIN') {
      return (
        <div
          className={styles.quickContainter}
          style={{
            backgroundImage: `url(${QuickTag})`,
            color: 'white'
          }}
        />
      );
    }
    return null;
  };

  const createName = () => {
    let modelName = '';
    if (floorplanData.marketingName) {
      modelName = floorplanData.marketingName.toUpperCase();
    } else {
      modelName = floorplanData.name.toUpperCase();
    }
    return modelName;
  };

  // eslint-disable-next-line arrow-body-style
  const locationDetailText = () => {
    return `${floorplanData.communityName} at ${salesCenterObject.locationName}`;
  };

const formatLayout = () => {
  let verticalLayout = GlobalConfig.get(GlobalConfig.Key.LAYOUT) === 'VERTICAL' ? true : false

  let styleObject = verticalLayout ? {
    background: '#fff',
    border: '1px solid #bfbfbf',
    display: 'flex',
    flexDirection: 'column',
    width: '325px',
    marginLeft: '20px',
    marginRight: '20px',
    borderRadius: '4px',
    height: '95%',
  } : {
    background: '#fff',
    border: '1px solid #bfbfbf',
    display: 'flex',
    flexDirection: 'column',
    width: '325px',
    marginLeft: '10px',
    marginRight: '10px',
    borderRadius: '4px',
    height: '100%',
  }

  styleObject.fontFamily = isActiveAdult ? 'Optima' : 'Avenir';

  return styleObject;
}

  return (
    <div
      className={styles.BuilderCardWeb}
      style={formatLayout()}
    >
      <div className={styles.modelImgContainer}>
        <img alt="home" src={floorplanData.image} />
        {floorplanData.saleType && checkFloorplanType()}
      </div>
      {floorplanData.saleType === 'QUICKMOVEIN' ? (
        <button
          onClick={() => sendMessage()}
          type="button"
          style={isActiveAdult ? {
            backgroundColor: '#621244',
            fontFamily: 'Optima',
          } : {
            backgroundColor: '#1A3464',
            fontFamily: 'Avenir',
          }}
        >
          View
        </button>
      )
        : (
          <button
            onClick={() => sendMessage()}
            type="button"
            style={isActiveAdult ? {
              backgroundColor: '#621244',
              fontFamily: 'Optima',
            } : {
              backgroundColor: '#1A3464',
              fontFamily: 'Avenir',
            }}
          >
            Personalize
          </button>
        )}
      <div className={styles.headlineDetails}>
        <div style={{ height: '100%'}}>
          <span
            style={isActiveAdult ? {
              color: '#50A3A2',
            } : {
              color: '#1A3464',
            }}
          >
            {createName()}
          </span>
          <br />
          <span
            className={styles.underlineDetail}
            style={isActiveAdult ? {
              color: 'rgb(153, 153, 153)',
            } : {
              color: '#1A3464',
            }}
          >
            {locationDetailText()}
          </span>
        </div>
        <div>
          <span style={{
            alignSelf: 'center',
          }}>
            {floorplanData.basePrice}
          </span>
          <br />
          {floorplanData.saleType === 'QUICKMOVEIN' ? (
            <span
              style={isActiveAdult ? {
                color: 'rgb(153, 153, 153)',
                fontSize: '.5rem',
                fontWeight: '400',
              } : {
                color: '#1A3464',
                fontSize: '.5rem',
                fontWeight: '400',
              }}
            >
              {floorplanData.location}
            </span>
          ) : (
            <span
              className={styles.underlineDetail}
              style={isActiveAdult ? {
                color: 'rgb(153, 153, 153)',
              } : {
                color: '#1A3464',
              }}
            >
              Starting at
            </span>
          )}
        </div>
      </div>
      <div>
        <span>
          <img alt="bed count" src={isActiveAdult ? adultBedIcon : newBedIcon} />
          <div
            style={isActiveAdult ? {
              color: 'rgb(153, 153, 153)',
            } : {
              color: '#1A3464',
            }}
          >
            {floorplanData.descrBedrooms}
          </div>
        </span>
        <span>
          <img alt="bath count" src={isActiveAdult ? adultBathIcon : newBathIcon} />
          <div
            style={isActiveAdult ? {
              color: 'rgb(153, 153, 153)',
            } : {
              color: '#1A3464',
            }}
          >
            {floorplanData.descrBath}
          </div>
        </span>
        <span>
          <img alt="sqft" src={isActiveAdult ? adultSqFtIcon : newSqFtIcon} />
          <div
            style={isActiveAdult ? {
              color: 'rgb(153, 153, 153)',
            } : {
              color: '#1A3464',
            }}
          >
            {floorplanData.descrSqf}
          </div>
        </span>
      </div>
      <div>
        <button
          disabled={floorplanData.saleType === 'QUICKMOVEIN'}
          onClick={() => sendHSM()}
          type="button"
          style={isActiveAdult ? {
            color: '#621244',
            border: '1px solid #621244',

          } : {
            color: '#1A3464',
          }}
        >
          Homesites
        </button>
        <button
          disabled={!hasPhotos()}
          onClick={() => {
            //console.log(`buttonclick: floorplanData.index=${floorplanData.index}, floorplanData=${floorplanData && JSON.stringify(floorplanData)}`)
              toggleGalleryActive(floorplanData.index)
            }
          }
          type="button"
          style={isActiveAdult ? {
            color: '#621244',
            border: '1px solid #621244',

          } : {
            color: '#1A3464',
          }}
        >
          Photos
        </button>
        <button
          disabled={!floorplanData.matterPortTour}
          onClick={() => activateiFrame(floorplanData.matterPortTour)}
          type="button"
          style={isActiveAdult ? {
            color: '#621244',
            border: '1px solid #621244',

          } : {
            color: '#1A3464',
          }}
        >
          Virtual Tour
        </button>
        <button
          onClick={() => {
            toggleCompareActive(floorplanData.index)
          }}
          type="button"
          disabled={floorplanData.saleType === 'QUICKMOVEIN'}
          style={isActiveAdult ? {
            color: '#621244',
            border: '1px solid #621244',

          } : {
            color: '#1A3464',
          }}
        >
          Compare
        </button>
      </div>
    </div>
  );
};

export default BuilderCard;
